import { gql } from "@apollo/client";

import { EmailIntegration } from "Services/state/agent/AgentInformationSlice";

export const AGENTS_QUERY_HARD_LIMIT = 1000 as const;

export const getAgentsForInternalTransfer = gql`
    query ($queryParams: QueryParams!) {
        findAllAgents(queryParams: $queryParams) {
            items {
                name
                twilioContactUri
                twilioSid
                isAvailable
                status
                conferenceStatus
                email
                isAiAgent
            }
        }
    }
`;

export const getAgentShared = gql`
    query ($queryParams: QueryParams!) {
        findAllAgents(queryParams: $queryParams) {
            items {
                id
                name
                status
                email
                twilioSid
                isAvailable
                twilioContactUri
                twilioStatusChangedAt
                isAiAgent
                skills {
                    name
                }
                teams {
                    id
                    name
                }
                conferenceStatus
                reservations {
                    task {
                        acceptedAt
                        type
                        canceledAt
                        wrappedAt
                        completedAt
                        deletedAt
                        attributes
                        channel
                        sid
                    }
                }
            }
            total
            page
            pageSize
            hasMore
        }
    }
`;

export type getAuthenticatedUserReturn = {
    getAuthenticatedUser: {
        name?: string;
        email?: string;
        workerSid?: string | null;
        workerUri?: string;
        onActiveCall?: boolean;
        brandId: string | null;
        status?: string;
        statusChangedAt?: string;
        attributes?: Record<string, any>;
        preferences?: Record<string, any>;
        gmailIntegration?: EmailIntegration[];
        outlookIntegrations?: any[];
    };
};

export const getAuthenticatedUser = gql`
    query {
        getAuthenticatedUser {
            name
            email
            twilioSid
            twilioContactUri
            statusChangedAt
            status
            twilioAttributes
            preferences
            gmailIntegration {
                authorizationStatus
                createdAt
                emailAddress
            }
            outlookIntegrations {
                id
                authorizationStatus
                createdAt
                emailAddress
                signatureHtml
            }
        }
    }
`;
